import { Outlet } from 'react-router-dom';
import Header from './components/common/Header'
import Footer from './components/common/Footer'
import './App.css';
import { useTranslation } from 'react-i18next';

function App() {
  const { t } = useTranslation();

  setTimeout(() => {
    document.title = `Deskshare - ${t('title')}`;
  }, 1000)

  return (
    <div className='page'>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;
