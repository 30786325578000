import React, { memo, useEffect } from 'react';
import uuid from 'react-uuid';
import PropTypes from 'prop-types';
import '../../static/css/productPage/comments.css';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Comments = ({ review }) => {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let { productSlug } = useParams();

    return (
        <div className="customer-comments">
            <div className="comments-heading">
                <p>{t('product_page.comments.title')}</p>
                <Link to={`/${productSlug}/reviews/`} className='more-reviews'>{t('product_page.comments.more')}{" "}{">>"}</Link>
            </div>
            <div className="comment-card-container">
                {review ?
                    review.slice(0, 4).map((comment) => {
                        return (
                            <div className="comment-card" key={uuid()}>
                                {comment.review_title ? <h4><q>{comment?.review_title}</q></h4> : ""}
                                <p>{comment?.review}</p>
                                <p>-{" "}{comment?.reviewer_name}</p>
                            </div>
                        );
                    })
                    : ""
                }
            </div>
        </div>
    );
};

Comments.propTypes = {
    review: PropTypes.string
};

export default memo(Comments);