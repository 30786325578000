import React from 'react';
import PropTypes from 'prop-types';
import { MdOutlineFileDownload } from "react-icons/md";
import '../../static/css/productPage/freeDownload.css';
import { useTranslation } from 'react-i18next';

const FreeDownload = ({ title, downloadLink, type }) => {
  const { t } = useTranslation();
  return (
    <div className='free-download'>
      <MdOutlineFileDownload className='download-icon' />
      <div className="content-top">
        {
          type === "free" ?
            <strong className='heading'><big>{t('product_page.cart.free_heading1')}{" "}{title}{" "}{t('product_page.cart.free_heading2')}</big></strong> :
            <strong className='heading'><big>{t('product_page.free_download.title')}</big></strong>
        }
        <p className='content-bottom'>{t('product_page.free_download.description')}</p>
      </div>
      {window.innerWidth > 430 ?  
        <button className='free-download-button' style={{ maxWidth: '360px' }}><a href={downloadLink}>{t('product_page.cart.download')}</a></button>
        : <button className='free-download-button' ><a href={downloadLink}>{t('product_page.cart.download')}</a></button>

      }
    </div>
  );
};

FreeDownload.propTypes = {
  title: PropTypes.string,
  downloadLink: PropTypes.object,
  type: PropTypes.string
};

export default FreeDownload;