import React, { Suspense } from 'react';
import './i18n';
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ProductPage from './pages/ProductPage';
import { HomePage } from './pages/HomePage';
import DownloadPage from './pages/DownloadPage';
import SupportForums from './pages/support/SupportForums';
import TechnicalSupport from './pages/support/TechnicalSupport';
import LostLicenseKey from './pages/support/LostLicenseKey';
import PurchasePrioritySupport from './pages/support/PurchasePrioritySupport';
import ContactUs from './pages/company/ContactUs';
import AboutDeskshare from './pages/company/AboutDeskshare';
import Articles from './pages/resources/Articles';
import VideoTutorialsAndManuals from './pages/resources/VideoTutorialsAndManuals';
import MediaCoverage from './pages/MediaCoverage';
import ArticleBlock from './components/ArticleBlock';
import WhatsNew from './pages/WhatsNew';
import NotFound from './pages/NotFound';
import Reviews from './pages/Reviews';
import DetailedArticle from './pages/support/DetailedArticle';
import MyPCInfo from './pages/MyPCInfo';
import UserManual from './pages/support/UserManual';
import CameraRequest from './pages/CameraRequest';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PrivacyPolicyApp from './pages/PrivacyPolicyApp';
import Faq from './pages/support/Faq';
import { AddLangToPath } from './helper/AddLanguagePath';

const router = createBrowserRouter([
  {
    element: <App />,
    // loader: appdata,
    children: [
      {
        path: AddLangToPath("/"),
        element: <HomePage />,
        // loader:homedata
      },
      {
        path: AddLangToPath("/:productSlug"),
        element: <ProductPage />,
        // loader: productdata,
      },
      {
        path: AddLangToPath("/:productSlug/whatsnew/:whatsNewSlug"),
        element: <WhatsNew />,
        // loader: productdata,
      },
      {
        path: AddLangToPath("/:productSlug/reviews"),
        element: <Reviews />,
        // loader: productdata,
      },
      {
        path: AddLangToPath("/download"),
        element: <DownloadPage />,
        // loader:homedata
      },
      {
        path: "/forum/",
        element: <SupportForums />,
      },
      {
        path: AddLangToPath("/product-support"),
        element: <TechnicalSupport />,
      },
      {
        path: AddLangToPath("/lost-license-key"),
        element: <LostLicenseKey />,
      },
      {
        path: AddLangToPath("/priority-support"),
        element: <PurchasePrioritySupport />,
      },
      {
        path: AddLangToPath("/articles"),
        element: <Articles />,
        children: [
          {
            path: AddLangToPath("/articles/:articleSlug"),
            element: <ArticleBlock />,
          }
        ]
      },
      {
        path: AddLangToPath("/:articleSlug/:detailedArticleSlug"),
        element: <DetailedArticle />,
      },
      {
        path: AddLangToPath("/tutorials-manuals"),
        element: <VideoTutorialsAndManuals />,
      },
      {
        path: AddLangToPath("/help/:productSlug/:guideSlug"),
        element: <UserManual />,
      },
      {
        path: AddLangToPath("/faq/:productSlug/:guideSlug"),
        element: <Faq />,
      },
      {
        path: AddLangToPath("/about-deskshare"),
        element: <AboutDeskshare />,
      },
      {
        path: AddLangToPath("/contact-us"),
        element: <ContactUs />,
      },
      {
        path: AddLangToPath("/media-coverage"),
        element: <MediaCoverage />,
      },
      {
        path: AddLangToPath("/mypcinfo"),
        element: <MyPCInfo />,
      },
      {
        path: AddLangToPath("/camera-request"),
        element: <CameraRequest />,
      },
      {
        path: AddLangToPath("/privacy-policy"),
        element: <PrivacyPolicy />,
      },
      {
        path: AddLangToPath("/app-privacy-policy"),
        element: <PrivacyPolicyApp />,
      },
      {
        path: AddLangToPath("*"),
        element: <NotFound />,
      }
    ],
  },
  {
    path: AddLangToPath("*"),
    element: <NotFound />,
  }
]);

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback="Loading...">
      <RouterProvider router={router} />
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
