import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faYoutube } from '@fortawesome/free-brands-svg-icons'
import '../../static/css/homePage/homeFooter.css'
import { useTranslation } from 'react-i18next';
import { AddLangToPath } from '../../helper/AddLanguagePath';
import IPDetails from '../../helper/IPDetails';
import i18n from "i18next"


const HomeFooter = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState({
        software: false,
        support: false,
        languages: false
    });

    const languages = useMemo(() => [
        { id: 'en', name: 'English' },
        { id: 'de', name: 'Deutsch' },
        { id: 'fr', name: 'Francais' },
        { id: 'es', name: 'Espanol' },
        { id: 'pt', name: 'Portugues' },
        { id: 'it', name: 'Italiano' },
    ], []);

    const [selectedLanguage, setSelectedLanguage] = useState('en');

    useEffect(() => {
        if (!localStorage.getItem('lang')) {
            IPDetails().then((data) => {
                const ipLang = data.country_code.toLowerCase();
                const langExists = languages.some(lang => lang.id === ipLang);
                if (langExists) {
                    setSelectedLanguage(ipLang);
                    localStorage.setItem('lang', ipLang);
                    i18n.changeLanguage(ipLang);
                    if (ipLang !== 'en') {
                        window.location.pathname = `/${ipLang}`;
                    }
                } else {
                    setSelectedLanguage('en');
                    localStorage.setItem('lang', 'en');
                    i18n.changeLanguage('en');
                }
            });
        } else {
            const userLang = window.location.pathname.split('/')[1];
            const langExists = languages.some(lang => lang.id === userLang);
            if (langExists) {
                setSelectedLanguage(userLang);
                localStorage.setItem('lang', userLang);
                i18n.changeLanguage(userLang);
            } else {
                setSelectedLanguage('en');
                localStorage.setItem('lang', 'en');
                i18n.changeLanguage('en');
            }
        }
    }, [selectedLanguage, languages]);

    const changeLanguage = (newLang) => {
        const langExists = languages.some(lang => lang.id === newLang);
        if (langExists) {
            setSelectedLanguage(newLang);
            localStorage.setItem('lang', newLang);
            let mypath = window.location.pathname.split('/');
            if (languages.some(lang => lang.id === mypath[1])) {
                mypath = mypath.slice(2);
            }
            mypath = mypath.filter(path => path !== '').join('/');
            if (newLang === 'en') {
                window.location.pathname = `/${mypath}`;
            } else {
                window.location.pathname = `/${newLang}/${mypath}`;
            }
        } else {
            setSelectedLanguage('en');
            localStorage.setItem('lang', 'en');
        }
    };

    return (
        <div className='home-footer'>
            <div className="left-block">
                <div>
                    <div onClick={() => {
                        if (window.innerWidth <= 430) {
                            setIsOpen({ software: !isOpen.software, support: false, languages: false })
                        }
                    }}>
                        <h5> {t('homepage.homepage_footer.software_categories.title')}</h5>
                        <svg onClick={() => setIsOpen({ software: !isOpen.software, support: false, languages: false })} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" className={isOpen.software ? "svg-inline--fa fa-caret-down flipped" : "svg-inline--fa fa-caret-down"} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                            <path fill="#b9b9b9" d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"></path>
                        </svg>
                    </div>
                    <div style={window.innerWidth <= 430 ? { display: isOpen.software ? 'block' : 'none' } : { display: 'block' }}>
                        <p><Link to={AddLangToPath('/video-surveillance-software')}>{t('homepage.homepage_footer.software_categories.video_surveillance')}</Link></p>
                        <p><Link to={AddLangToPath('/video-screen-capture')}>{t('homepage.homepage_footer.software_categories.screen_recorder')}</Link></p>
                        <p><Link to={AddLangToPath('/ftp-client')}>{t('homepage.homepage_footer.software_categories.ftp_client')}</Link></p>
                        <p><Link to={AddLangToPath('/text-to-speech-software')}>{t('homepage.homepage_footer.software_categories.speech_tools')}</Link></p>
                        {/* <p><Link to={AddLangToPath('/paper-scanner-pro')}>{t('homepage.homepage_footer.software_categories.document_management')}</Link></p> */}
                    </div>
                </div>
                <div>
                    <div onClick={() => {
                        if (window.innerWidth <= 430) {
                            setIsOpen({ support: !isOpen.support, software: false, languages: false })
                        }
                    }}>
                        <h5>{t('homepage.homepage_footer.homepage_support.title')}</h5>
                        <svg onClick={() => setIsOpen({ support: !isOpen.support, software: false, languages: false })} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" className={isOpen.support ? "svg-inline--fa fa-caret-down flipped" : "svg-inline--fa fa-caret-down"} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                            <path fill="#b9b9b9" d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"></path>
                        </svg>
                    </div>
                    <div style={window.innerWidth <= 430 ? { display: isOpen.support ? 'block' : 'none' } : { display: 'block' }}>
                        <p><Link to={AddLangToPath('/lost-license-key')}>{t('homepage.homepage_footer.homepage_support.retrieve')}</Link></p>
                        <p><Link to='/forum/'>{t('homepage.homepage_footer.homepage_support.forum')}</Link></p>
                        <p><Link to={AddLangToPath('/tutorials-manuals')}>{t('homepage.homepage_footer.homepage_support.tutorials')}</Link></p>
                        <p><Link to={AddLangToPath('/articles/security-monitor-pro')}>{t('homepage.homepage_footer.homepage_support.articles')}</Link></p>
                        <p><Link to={AddLangToPath('/product-support')}>{t('homepage.homepage_footer.homepage_support.product_support')}</Link></p>
                    </div>
                </div>
                <div>
                    <div onClick={() => {
                        if (window.innerWidth <= 430) {
                            setIsOpen({ languages: !isOpen.languages, support: false, software: false })
                        }
                    }}>
                        <h5>Languages
                        </h5>
                        <svg onClick={() => setIsOpen({ languages: !isOpen.languages, support: false, software: false })} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" className={isOpen.languages ? "svg-inline--fa fa-caret-down flipped" : "svg-inline--fa fa-caret-down"} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                            <path fill="#b9b9b9" d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"></path>
                        </svg>
                    </div>
                    <div style={window.innerWidth <= 430 ? { display: isOpen.languages ? 'block' : 'none' } : { display: 'block' }}>
                        <p><button onClick={() => { changeLanguage('en') }} >English</button></p>
                        <p><button onClick={() => { changeLanguage('de') }} >Deutsch</button></p>
                        <p><button onClick={() => { changeLanguage('fr') }} >Francais</button></p>
                        <p><button onClick={() => { changeLanguage('es') }} >Espanol</button></p>
                        <p><button onClick={() => { changeLanguage('pt') }} >Portugues</button></p>
                        <p><button onClick={() => { changeLanguage('it') }} >Italiano</button></p>
                    </div>
                </div>
            </div>

            {/* <div className="left-block">
                {footer.map((item) => {
                    return (
                        <div key={uuid()}>
                            {window.innerWidth <= 430 ? (
                                <>
                                    <h5 onClick={() => {
                                        if (openedDropdown === item.title) {
                                            setOpenedDropdown(null);
                                        } else {
                                            setOpenedDropdown(item.title);
                                        }
                                    }}>{item.title}
                                    </h5>
                                    {
                                        openedDropdown === item.title && item.content.map((content) => {
                                            return (
                                                item.title !== 'Languages' ? (
                                                    <Link to={content.link}>{content.name}</Link>
                                                ) : (
                                                    <button onClick={() => { localStorage.setItem('lang', content.link); window.location.reload() }}>{content.name}</button>
                                                )
                                            );
                                        })
                                    }
                                </>
                            ) : (
                                <>
                                    <h5>{item.title}</h5>
                                    {item.content.map((content) => {
                                        return (
                                            <p key={uuid()}>
                                                {
                                                    item.title !== 'Languages' ? (
                                                        <Link to={content.link}>{content.name}</Link>
                                                    ) : (
                                                        <button onClick={() => { localStorage.setItem('lang', content.link); window.location.reload() }}>{content.name}</button>
                                                    )
                                                }
                                            </p>
                                        )
                                    })}
                                </>
                            )}
                        </div>
                    )
                })}
            </div> */}
            <div className="right-block" >
                <a href={AddLangToPath("/")}>
                    <img src="/images/deskshare_logo.png" alt="deskshare-icon" />
                </a>
                <div className="social">
                    <h5>{t('homepage.homepage_footer.social_media')}</h5>
                    <div className="social-icon">
                        <Link to={'https://www.facebook.com/people/Deskshare/100064110930535/'}>
                            <FontAwesomeIcon icon={faFacebookF} />
                        </Link>
                        <Link to={'https://www.youtube.com/@DeskShareInc'}>
                            <FontAwesomeIcon icon={faYoutube} />
                        </Link>
                    </div>
                </div>
            </div>
        </div >
    )
}

HomeFooter.propTypes = {
    footer: PropTypes.array.isRequired
}

export default HomeFooter