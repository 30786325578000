import React from 'react'
import '.././static/css/thankYou.css'
import { useTranslation } from 'react-i18next';
import { AddLangToPath } from '../helper/AddLanguagePath';

const ThankYou = ({ applicationName }) => {
    const { t } = useTranslation();
    return (
        <div className="contactus-success">
            <div className='success-page'>
                <svg width="324" height="226" viewBox="0 0 324 226" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M158.366 110.532L38.8 205.134V14.8729C62.2737 33.5424 91.8281 57.1866 115.718 76.3272C127.94 86.1192 138.679 94.7324 146.362 100.898C150.204 103.98 153.282 106.451 155.399 108.151L157.831 110.103L158.366 110.532Z" fill="#5183C0" stroke="white" stroke-width="2" />
                    <path d="M166.397 107.017L283 15.0622V204.938L162.615 110L166.397 107.017Z" fill="#5183C0" stroke="white" stroke-width="2" />
                    <path d="M38 207.2H283.7L160.85 110L38 207.2Z" fill="#5183C0" stroke="white" stroke-width="3" />
                    <path d="M283.5 12.8H37.8V21.6704L160.5 120L283.5 21.7409V12.8Z" fill="#003F8B" />
                </svg>
                <div className="success-message">
                    <h3>{t('thank_you.title')}</h3>
                    <p>{t('thank_you.description1')}{" "}{applicationName ? <>{t('thank_you.description2')}{" "}<b style={{ color: '#3498db' }}>{applicationName}</b></> : ""}{" "}{t('thank_you.description3')}</p>
                </div>
                <button type="button"><a href={AddLangToPath("/")}>Return to Home Page</a></button>
            </div>
        </div>
    )
}

export default ThankYou