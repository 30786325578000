import React, { useEffect, useState } from 'react'
import '../../static/css/resources/articles.css';
import { Link, useParams } from 'react-router-dom';
import ArticleBlock from '../../components/ArticleBlock';
import { commonAPI } from '../../api/commonAPI';
import { useTranslation } from 'react-i18next';
import { AddLangToPath } from '../../helper/AddLanguagePath';

const Articles = () => {
  const { t } = useTranslation();
  document.title = t('resources.articles.title')
  let { articleSlug } = useParams();
  const [articleData, setArticleData] = useState(null);

  useEffect(() => {
    commonAPI('allarticles').then((result) => setArticleData(result));
  }, []);

  let article = (articleData?.find(product => Object.keys(product)[0] === articleSlug));

  if (articleData) {
    return (
      <div className='article-container'>
        <div className='articles-header'>
          <h1>{t('resources.articles.title')}</h1>
          <p>{t('resources.articles.description')} <Link to={AddLangToPath('/contact-us/')}>{t('resources.articles.contact')}</Link>.</p>
        </div>
        <div className="articles-content">
          <div className="articles-list">
            <h3>{t('resources.articles.side_bar')}</h3>
            {
              articleData.map((product) => {
                if (Object.keys(Object.values(product)[0].article).length > 0) {
                  return (
                    <Link key={Object.keys(product)[0]} to={AddLangToPath(`${Object.keys(product)[0]}`)} className="product-name">
                      <p style={articleSlug === Object.keys(product)[0] ? { fontWeight: 'bold' } : { fontWeight: 'normal' }}>
                        {Object.values(product)[0].product_name}
                      </p>
                    </Link>
                  );
                } else {
                  return null;
                }
              })
            }
          </div>
          {
            articleSlug === undefined ?
              <div className="article-landing">
                <h2>Welcome to our articles page</h2>
                <p>Our articles are organized by product. To view articles for a specific product, click on the product name in the list to the left.</p>
              </div>
              : <ArticleBlock data={article} />
          }
        </div>
      </div>
    )
  }
}

export default Articles