import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import '../../static/css/homePage/activeDiscussions.css'
import { useTranslation } from 'react-i18next';
import { forumData } from '../../api/supportForumAPI';

const ActiveDiscussions = () => {
    const { t } = useTranslation();
    const [discussion, setDiscussion] = useState();

    useEffect(() => {
        forumData(`/forum/latest/`).then((result) => setDiscussion(result));
    }, []);

    if (discussion) {
        return (
            <div className='discussion'>
                <p className='discussion-title'>{t('homepage.active_discussion')}</p>
                <div className='product-discussion' key={uuid()}>
                    <table className='discussion-list' >
                        <tbody>
                            {
                                discussion.data.map((discussion) => {
                                    return (
                                        <tr key={uuid()}>
                                            <td className='discussion-bullet'>{">"}</td>
                                            <td className='discussion-topic'>
                                                <a href={discussion.url}>{discussion.subject}</a>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    } else {
        return null
    }
}

ActiveDiscussions.propTypes = {
    discussion: PropTypes.array
}

export default ActiveDiscussions