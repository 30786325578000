import React, { memo, useEffect, useMemo, useState } from 'react'
import { Helmet } from "react-helmet";
import '../static/css/productPage/productPage.css'
import ProductTitle from '../components/productPage/ProductTitle'
import Product from '../components/productPage/Product'
import ProductInfo from '../components/productPage/ProductInfo'
import Comments from '../components/productPage/Comments'
import FreeDownload from '../components/productPage/FreeDownload'
import KeyInformation from '../components/productPage/KeyInformation'
import Data from '../data/productPages.json'
import { useParams } from 'react-router-dom'
import { fetchData } from '../api/productPageAPI';

const ProductPage = () => {
  const { productSlug } = useParams();
  const productData = useMemo(() => Data[productSlug], [productSlug]);
  const [product, setProduct] = useState({});
  const lang = localStorage.getItem('lang');

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData(productSlug).then((result) => setProduct(result)).catch((error) => console.error("error throuwn Pratik "));
  }, [productSlug]);

  if (product?.meta_data) {
    return (
      <>
        {product.meta_data[0]?.meta_description ?
          <Helmet>
            <html lang={lang}></html>
            <meta name="description" content={product?.meta_data[0].meta_description} />
            <meta name="keywords" content={product?.meta_data[0].meta_keywords} />
            <meta property="og:title" content={product?.meta_data[0].meta_title} />
            <meta property="og:description" content={product?.meta_data[0].meta_description} />
            <meta property="og:image" content={product?.meta_data[0].meta_image} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="website" />
          </Helmet>
          : null}
        <div className="productPage">
          <ProductTitle title={product?.product_name} subTitle={product?.product_title} />
          <Product
            productVideo={product?.videos}
            productVideoImage={product?.video_image}
            productImages={product?.screenshot}
            currentVersion={product?.current_version}
            os={product?.supporting_os}
            server={product?.product_server}
            productFeatures={product?.feature}
            newVersion={product?.whats_new_slug}
            downloadLink={productData.getProduct}
            type={product?.pricing_type}
            inApp={product?.in_app_purchase}
            cartItem={product?.cart_item}
            cartType={product?.cart_type}
            cartTitle={product?.cart_title}
            cartDescription={product?.cart_description}
            downloadProduct={product?.download_link}
          />
          <ProductInfo detail={product?.product_information} />
          <Comments review={product?.review} />
          <FreeDownload
            downloadLink={product?.download_link}
            title={product?.product_name}
            type={product?.pricing_type}
          />
          <KeyInformation
            featureTable={product?.feature_table}
            quickTips={product?.article}
            comparisonData={productData.comparisonTableData}
            comarisonTable={product?.comparison_table}
          />
        </div>
      </>
    )
  }
}

export default memo(ProductPage)