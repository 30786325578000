import React, { useEffect } from 'react';
import '../static/css/downloadPage/downloadPage.css';
import DownloadTitle from '../components/downloadPage/DownloadTitle';
import DownloadTable from '../components/downloadPage/DownloadTable';


const DownloadPage = () => {

    document.title = 'Download Deskshare Products';

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='download-page'>
            <DownloadTitle />
            <DownloadTable />
        </div>
    )
}

export default DownloadPage