import React from 'react'
import '../../static/css/productPage/newsLetter.css'
import { useTranslation } from 'react-i18next';

const NewsLetter = () => {
    const { t } = useTranslation();
    return (
        <div className='newsletter'>
            <div className="sidecardheader">{t('product_page.newsletter.title')}</div>
            <div className="sidecardsection">
                <div className="newstext">{t('product_page.newsletter.description')}</div>
                <form method="post" action="">
                    <div className="form-group" id="Newsletter_form">
                        <input id="" type="email" name="email" placeholder={t('product_page.newsletter.placeholder')} />
                        <button id="" className='subscribe' type="submit">{t('product_page.newsletter.button')}</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default NewsLetter;