import React from 'react';
import PropTypes from 'prop-types';
import Characteristics from './Characteristics';
import QuickLetter from './QuickLetter';
import '../../static/css/productPage/keyInformation.css';
import Comparison from './Comparison';

const KeyInformation = ({ featureTable, featureTable1, quickTips, comparisonData, comarisonTable  }) => {
  return (
    <div className='key-information'>
      <Characteristics featureTable={featureTable} featureTable1={featureTable1} />
      {!comparisonData && comarisonTable ? null :
        <Comparison comparison={comparisonData} comarisonTable={comarisonTable}/>
      }
      <QuickLetter quickLinks={quickTips} />
    </div>
  );
};

KeyInformation.propTypes = {
  featureTable: PropTypes.array,
  quickTips: PropTypes.array,
  comparisonData: PropTypes.object
};

export default KeyInformation;