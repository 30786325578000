import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import '.././static/css/cameraRequest.css';
import { CameraRequestAPI } from '../api/cameraRequestAPI';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ThankYou from '../components/ThankYou';

const CameraRequest = () => {
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required(t('camera_request.form1.validation_messages.f_name')),
        lastName: Yup.string().required(t('camera_request.form1.validation_messages.l_name')),
        email: Yup.string().email(t('camera_request.form1.validation_messages.email1')).required(t('camera_request.form1.validation_messages.email2')),
        cameraBrand: Yup.string().required(t('camera_request.form1.validation_messages.brand')),
        cameraModel: Yup.string().required(t('camera_request.form1.validation_messages.model')),
        cameraIP: Yup.string().required(t('camera_request.form1.validation_messages.ip')),
        portNumber: Yup.string().required(t('camera_request.form1.validation_messages.port')),
    });

    const navigate = useNavigate();
    const [phone, setPhone] = useState()
    const [apiResponse, setApiResponse] = useState()
    const [showResponse, setShowResponse] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (apiResponse !== undefined) {
            setShowResponse(true);
            setTimeout(() => {
                setShowResponse(false);
                navigate('/');
            }, 10000);
        }
    }, [apiResponse, navigate]);

    const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
        setApiResponse(undefined);
        let formData = { ...values, phone };
        const response = await CameraRequestAPI(formData);
        setApiResponse(response);
        if (response.status === 200) {
            resetForm();
            setPhone('');
        }
        setSubmitting(false);
    }

    document.title = t('camera_request.title');

    return (
        <>
            {
                showResponse && apiResponse && apiResponse.status === 200 ?
                    <ThankYou applicationName={'camera request'} />
                    :
                    <div className='request-camera-page'>
                        <h1>{t('camera_request.title')}</h1>
                        {showResponse && <p style={{ color: 'red' }}>{apiResponse?.message}</p>}
                        <hr />
                        <div className="forms">
                            <Formik
                                initialValues={{ firstName: '', lastName: '', email: '', cameraBrand: '', cameraModel: '', cameraIP: '', portNumber: '', phone: '' }}
                                validationSchema={validationSchema}
                                onSubmit={(values, formikBag) => { handleFormSubmit(values, formikBag) }}
                            >
                                {({ values }) => {
                                    return (
                                        <Form>
                                            <div className="form-container">
                                                <div className="request-form-block">
                                                    <p><b>{t('camera_request.form1.title')}</b></p>
                                                    <hr />
                                                    <div className="camera-request-form1">
                                                        <label htmlFor="firstName">{t('camera_request.form1.label.full_name')}</label>
                                                        <div></div>
                                                        <div>
                                                            <Field name="firstName" placeholder={t('camera_request.form1.placeholder.f_name')} />
                                                            <ErrorMessage className='error-message' name="firstName" component="div" />
                                                        </div>
                                                        <div>
                                                            <Field name="lastName" placeholder={t('camera_request.form1.placeholder.l_name')} />
                                                            <ErrorMessage className='error-message' name="lastName" component="div" />
                                                        </div>
                                                        <label htmlFor="email">{t('camera_request.form1.label.email')}</label>
                                                        <label htmlFor="phone">{t('camera_request.form1.label.phone')}</label>
                                                        <div>
                                                            <Field type="email" name="email" placeholder='you@domain.com' />
                                                            <ErrorMessage className='error-message' name="email" component="div" />
                                                        </div>
                                                        <PhoneInput country={'us'} value={phone} onChange={phone => setPhone(phone)} />
                                                        <label htmlFor="cameraBrand">{t('camera_request.form1.label.brand')}</label>
                                                        <label htmlFor="cameraModel">{t('camera_request.form1.label.model')}</label>
                                                        <div>
                                                            <Field name="cameraBrand" placeholder='Axis' />
                                                            <ErrorMessage className='error-message' name="cameraBrand" component="div" />
                                                        </div>
                                                        <div>
                                                            <Field name="cameraModel" placeholder='M2036-LE' />
                                                            <ErrorMessage className='error-message' name="cameraModel" component="div" />
                                                        </div>
                                                        <label htmlFor="cameraIP">{t('camera_request.form1.label.ip')}</label>
                                                        <label htmlFor="portNumber">{t('camera_request.form1.label.port')}</label>
                                                        <div>
                                                            <Field name="cameraIP" placeholder='24.18.221.108' />
                                                            <ErrorMessage className='error-message' name="cameraIP" component="div" />
                                                        </div>
                                                        <div>
                                                            <Field name="portNumber" placeholder='80' />
                                                            <ErrorMessage className='error-message' name="portNumber" component="div" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="request-form-block">
                                                    <p><b>{t('camera_request.form2.title')}</b></p>
                                                    <hr />
                                                    <div className="camera-request-form2">
                                                        <label htmlFor="username">{t('camera_request.form2.label.username')}</label>
                                                        <Field name="username" placeholder={t('camera_request.form2.placeholder.username')} />
                                                        <label htmlFor="username">{t('camera_request.form2.placeholder.password')}</label>
                                                        <Field type='password' name="password" placeholder={t('camera_request.form2.placeholder.password')} />
                                                        <label htmlFor="instruction">{t('camera_request.form2.label.instruction')}</label>
                                                        <Field
                                                            as="textarea"
                                                            name="instruction"
                                                            id="instruction"
                                                            maxLength="1000"
                                                            cols="30" rows="10"
                                                            placeholder={t('camera_request.form2.placeholder.instruction')} />
                                                    </div>
                                                </div>
                                            </div>
                                            <button className='form-submit' type="submit">{t('camera_request.submit')}</button>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>
                    </div>
            }
        </>
    )
}

export default CameraRequest