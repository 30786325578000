import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import '../../static/css/productPage/productFeatures.css';
import '../../static/css/productPage/cart.css';
import CurrencyConverter from '../../helper/CurrencyConverter';
import { useTranslation } from 'react-i18next';

CurrencyConverter();

function getDeviceType(userAgent) {
  const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|Touch/i.test(userAgent);

  return mobile;
}

export const FreeProductcart = ({ downloadProduct, cartTitle, cartDescription, inApp }) => {
  const { t } = useTranslation();
  const [sendLink, setSendLink] = useState(false);

  const handleSendLink = () => {
    setSendLink(true);
  };

  const title = cartTitle ? cartTitle.split(/\. |: /).map(s => s.replace(/:/g, '')) : null;
  const description = cartDescription ? cartDescription.split(/(?<=\. )/) : null;
  return (
    <>
      {
        !inApp ?
          <>
            <span className="cart-title">{t('product_page.cart.free_heading1')}{" "}{cartTitle}{" "}{t('product_page.cart.free_heading2')}</span>
            <hr className='top-line' />
            {!getDeviceType(navigator.userAgent) ?
              <>
                <button className='cart-button'>
                  <a href={downloadProduct}>{t('product_page.cart.download')}</a>
                </button>
                <div className='quality'>{t('product_page.cart.quality')}</div>
              </>
              :
              <>
                {sendLink ?
                  <>
                    <p className="mobile-cart-description">{t('product_page.cart.mail_description')}</p>
                    <span className='mail-info'>{t('product_page.cart.mail_info')}</span>
                    <input type="email" name="email" id="email" placeholder='Enter your email...' />
                    <button className='cart-button'>
                      <a href={downloadProduct}>Send Link</a>
                    </button>
                  </>
                  :
                  <>
                    <button className='cart-button' onClick={handleSendLink}>
                      {t('product_page.cart.download')}
                    </button>
                    <div className='quality'>{t('product_page.cart.quality')}</div>
                  </>
                }
              </>
            }
          </>
          : <>
            <span className="cart-title"> {title.map((line) => {
              return (
                <span key={line}>{line}<br /></span>
              )
            })} </span>
            <hr className='top-line' style={{ marginBottom: '4px' }} />
            {!getDeviceType(navigator.userAgent) ?
              <>
                <p className="cart-description">
                  <span> {description.map((desc) => {
                    return (
                      <span className='cart-desc-line' key={uuid()}>{desc}<br /></span>
                    )
                  })}</span>
                </p>
                <button className='cart-button' style={{ marginTop: '4px' }}>
                  <a href={downloadProduct}>{t('product_page.cart.download')}</a>
                </button>
                <div className='quality'>{t('product_page.cart.quality')}</div>
              </>
              :
              <>
                {sendLink ?
                  <>
                    <p className="mobile-cart-description">Looks like you’re not on desktop device!</p>
                    <span className='mail-info'>We'll send a link to your email so you can download later.</span>
                    <input type="email" name="email" id="email" placeholder='Enter your email...' />
                    <button className='cart-button' style={{ marginTop: '4px' }}>
                      <a href={downloadProduct}>Send Link</a>
                    </button>
                  </>
                  :
                  <>
                    <p className="cart-description">
                      <span> {description.map((desc) => {
                        return (
                          <span className='cart-desc-line' key={uuid()}>{desc}<br /></span>
                        )
                      })}</span>
                    </p>
                    <button className='cart-button' onClick={handleSendLink}>
                      {t('product_page.cart.download')}
                    </button>
                    <div className='quality'>{t('product_page.cart.quality')}</div>
                  </>
                }
              </>
            }
          </>
      }
    </>
  );
};

export const LicenseProductcartDropdown = ({ cartItem, cartTitle }) => {
  const { t } = useTranslation();
  const [license, setLicense] = useState(cartItem[0].cart_catagory);

  const initialQuantity = cartItem.reduce((acc, item) => {
    acc[item.cart_catagory] = 1;
    return acc;
  }, {});

  const [quantity, setQuantity] = useState(initialQuantity);
  const priceValue = JSON.parse(localStorage.getItem('currencyData'));

  const updateQuantity = useCallback((operation, count) => {
    if (count >= 0) {
      setQuantity(prevQuantity => ({
        ...prevQuantity,
        [operation]: count
      }))
    }
  }, []);

  const totalPrice = useMemo(() => {
    return Math.round(
      cartItem
        .filter(item => item.cart_catagory === license)
        .map(item => item.catagory_price * quantity[license] * priceValue.product_price)
        .reduce((a, b) => a + b, 0)
    );
  }, [priceValue.product_price, cartItem, quantity, license]);

  const handleBuyNowClick = useCallback(() => {
    const currentItem = cartItem.find(item => item.cart_catagory === license);
    const productId = currentItem.catagory_id;
    const productIds = Array(quantity[license]).fill(productId).join(',');
    window.open(`https://shop.deskshare.com/?add-to-cart=${productIds}`, '_blank');
  }, [license, quantity, cartItem]);

  return (
    <>
      <div className="license-container">
        <span className="cart-title">{cartTitle}</span>
        <select name="license" id="license"
          value={license}
          onChange={(e) => setLicense(e.target.value)}
        >
          {cartItem.map((item) => {
            return (
              <option key={item.id} value={item.cart_catagory}>{item.cart_catagory}{" "}{t('product_page.cart.license')}</option>
            )
          })}
        </select>
      </div>
      <div className='quantity-container'>
        <p>{t('product_page.cart.quantity')}</p>
        <div className='counter'>
          {
            (license === cartItem[1].cart_catagory) ?
              <>
                <button className='operation' onClick={() => { updateQuantity(Object.keys(quantity)[1], Object.values(quantity)[1] - 1) }}>-</button>
                <input className='count' type="text" value={Object.values(quantity)[1]} disabled />
                <button className='operation' onClick={() => { updateQuantity(Object.keys(quantity)[1], Object.values(quantity)[1] + 1) }}>+</button>
              </>
              :
              <>
                <button className='operation' onClick={() => { updateQuantity(Object.keys(quantity)[0], Object.values(quantity)[0] - 1) }}>-</button>
                <input className='count' type="text" value={Object.values(quantity)[0]} disabled />
                <button className='operation' onClick={() => { updateQuantity(Object.keys(quantity)[0], Object.values(quantity)[0] + 1) }}>+</button>
              </>
          }
        </div>
      </div>
      <hr className='top-line' />
      <div className="display-pricing">
        <div className="display-total">
          <span className="total"><b>Total</b></span >
          <span className="amount">
            <b>
              <span className="amout">
                {priceValue.currency_symbol}{" "}
                {totalPrice} {" "}
                {priceValue.currency_code}
              </span>
            </b>
          </span>
        </div>
        <span>{license === Object.keys(quantity)[1] ? t('product_page.cart.once') : t('product_page.cart.year')}</span>
      </div>
      {license === Object.keys(quantity)[1] ?
        <button className='cart-button'
          style={(Object.values(quantity)[1] > 0) ? { backgroundColor: 'rgb(40, 167, 69)' } : { backgroundColor: 'gray' }}
          disabled={Object.values(quantity)[1] === 0}
          onClick={handleBuyNowClick}
        >{t('product_page.cart.buy')}
        </button> :
        <button className='cart-button'
          style={(Object.values(quantity)[0] > 0) ? { backgroundColor: 'rgb(40, 167, 69)' } : { backgroundColor: 'gray' }}
          disabled={Object.values(quantity)[0] === 0}
          onClick={handleBuyNowClick}
        >{t('product_page.cart.buy')}
        </button>
      }
      <div className='quality'>{t('product_page.cart.quality')}</div>
      <div className="immediate">{t('product_page.cart.delivery')}</div>
    </>
  );
};

const LicenseProductcart = ({ cartTitle, cartDescription, cartItem }) => {
  const { t } = useTranslation();
  const initialQuantity = cartItem.reduce((acc, item, index) => {
    acc[item.cart_catagory] = index === 0 ? 1 : 0;
    return acc;
  }, {});

  const [quantity, setQuantity] = useState(initialQuantity);
  const TotalQuantity = useMemo(() => Object.values(quantity).reduce((old, value) => old + value, 0), [quantity]);
  const priceValue = JSON.parse(localStorage.getItem('currencyData'));

  const updateQuantity = (operation, count) => {
    if (count >= 0) {
      setQuantity({
        ...quantity,
        [operation]: count
      });
    };
  };

  const totalPrice = useMemo(() => {
    return Math.round(
      Object.values(quantity).reduce((r, a, i) => {
        const price = cartItem[i]?.catagory_price || 0;
        return r + a * price * priceValue?.product_price;
      }, 0)
    );
  }, [priceValue, quantity, cartItem]);


  const productIds = Object.keys(quantity).reduce((ids, license, index) => {
    const productId = cartItem[index]?.catagory_id;
    if (quantity[license] > 0) {
      return ids + (ids ? ',' : '') + Array(quantity[license]).fill(productId).join(',');
    } else {
      return ids;
    }
  }, '');

  return (
    <>
      <span className="cart-title">{cartTitle}</span>
      <hr className='top-line' style={{ marginBottom: '4px' }} />
      <p className="cart-description">{cartDescription}</p>
      <div className="quantity">
        <span>{t('product_page.cart.license')}</span>
        <span>{t('product_page.cart.price')}</span>
        <span>{t('product_page.cart.quantity')}</span>
      </div>
      {
        cartItem.map((item) => {
          return (
            <div key={item.id} className='quantity-container'>
              <button className="license-type"
                style={quantity[item.cart_catagory] > 0 ? { backgroundColor: 'rgba(0, 153, 254, 0.26' } : {}}
                onClick={() => { quantity[item.cart_catagory] > 0 ? updateQuantity(item.cart_catagory, 0) : updateQuantity(item.cart_catagory, quantity[item.cart_catagory] + 1) }} >
                <p className="type">{item.cart_catagory}</p>
                <span>{Math.round(item.catagory_price * priceValue.product_price)} {priceValue.currency_code}</span>
              </button>
              <div className='counter'>
                <button className='operation' onClick={() => { updateQuantity(item.cart_catagory, quantity[item.cart_catagory] - 1) }}>-</button>
                <input className='count' type="text" value={quantity[item.cart_catagory]} disabled />
                <button className='operation' onClick={() => { updateQuantity(item.cart_catagory, quantity[item.cart_catagory] + 1) }}>+</button>
              </div>
            </div>
          )
        })
      }
      {TotalQuantity !== 0 ?
        <hr className='top-line' style={{ marginBottom: '4px' }} />
        : ""}
      {
        cartItem.map((item) => {
          return (
            quantity[item.cart_catagory] !== 0 &&
            <div key={item.id} className="item-price-quantity" >
              <span className='choosed-products'>{item.cart_catagory}</span>
              <span className='product-prices'>{Math.round(quantity[item.cart_catagory] * item.catagory_price * priceValue.product_price)} {priceValue.currency_code}</span>
              <span className='product-quantities'>{quantity[item.cart_catagory]}</span>
            </div>
          )
        })
      }
      <hr className='top-line' />
      <div className="display-pricing">
        <div className="display-total">
          <span className="total"><b>{t('product_page.cart.total')}</b></span >
          <div className="total-amount-quantity">
            <span className="amount">
              <b>
                {priceValue.currency_symbol}{" "}
                {totalPrice}{" "}
                {priceValue.currency_code}
              </b>
            </span>
            <span className="payment-type">{t('product_page.cart.once')}</span>
          </div>

        </div>
      </div>
      <button target="_blank" className='cart-button'
        style={TotalQuantity > 0 ? { backgroundColor: 'rgb(40, 167, 69)' } : { backgroundColor: 'gray' }}
        disabled={TotalQuantity === 0}
        // window.open(download[linkText[0]], '_blank')
        onClick={() => { window.open(`https://shop.deskshare.com/?add-to-cart=${productIds}`, '_blank') }}
      >
        {t('product_page.cart.buy')}
      </button>
      <div className='quality'>{t('product_page.cart.quality')}</div>
      <div className="immediate">{t('product_page.cart.delivery')}</div>
    </>
  );
};

const CreditProductCart = ({ cartItem, cartTitle, cartDescription }) => {
  const { t } = useTranslation();
  const [credit, setCredit] = useState(cartItem[0].cart_catagory)
  const [price, setPrice] = useState(cartItem[0].catagory_price)
  const priceValue = JSON.parse(localStorage.getItem('currencyData'));

  const categoryObject = cartItem.find(item => item.cart_catagory === credit);

  const selectCredit = (credit, price) => {
    setCredit(credit);
    setPrice(price);
  };

  return (
    <>
      <span className="cart-title">{cartTitle}</span>
      <hr className='top-line' style={{ marginBottom: '4px' }} />
      <p className="cart-description">{cartDescription}</p>
      {
        cartItem.map((item) => {
          return (
            <div key={uuid()} className='credit-quantity-container'>
              <button className="license-type" style={credit === item.cart_catagory ? { backgroundColor: 'rgba(0, 153, 254, 0.26' } : {}} onClick={() => { selectCredit(item.cart_catagory, item.catagory_price) }} >
                <p className="type">{item.cart_catagory}</p>
                <span>{Math.round(item.catagory_price * priceValue.product_price)} {priceValue.currency_code}</span>
              </button>
            </div >
          )
        })
      }
      <hr className='top-line' />
      <div className="display-pricing">
        <div className="display-total">
          <span className="total"><b>{t('product_page.cart.total')}</b></span >
          <div className="total-amount-quantity">
            <span className="amount">
              <b>
                {priceValue.currency_symbol}{" "}
                {Math.round(priceValue.product_price * price)}
                {" "}
                {priceValue.currency_code}
              </b>
              <span>{t('product_page.cart.monthly')}</span>
            </span>
          </div>
        </div>
      </div>
      <button
        target="_blank"
        className='cart-button'
        style={{ backgroundColor: 'rgb(40, 167, 69)' }}
        onClick={() => { window.open(`https://shop.deskshare.com/?add-to-cart=${categoryObject.catagory_id}`) }}>
        Start Subscription
      </button>
      <div className='quality'>{t('product_page.cart.quality')}</div>
      <div className="immediate">{t('product_page.cart.delivery')}</div>
    </>
  );
};

const Cart = ({ productType, cartTitle, cartDescription, cartItem, cartType, downloadProduct, inApp }) => {
  if (productType === "free") {
    return (
      <div className='cart'>
        <FreeProductcart downloadProduct={downloadProduct} inApp={inApp} cartTitle={cartTitle} cartDescription={cartDescription} />
      </div>
    );
  }
  else if (cartType === "dropdown") {
    if (cartItem) {
      return (
        <div className='cart'>
          <LicenseProductcartDropdown cartItem={cartItem} cartTitle={cartTitle} />
        </div>
      );
    }
  }
  else if (cartType === "multi license") {
    return (
      <div className='cart'>
        <LicenseProductcart cartItem={cartItem} cartTitle={cartTitle} cartDescription={cartDescription} />
      </div>
    );
  } else if (cartType === 'multi credit') {
    return (
      <div className='cart'>
        <CreditProductCart cartItem={cartItem} cartTitle={cartTitle} cartDescription={cartDescription} />
      </div>
    );
  }
};


Cart.propTypes = {
  productType: PropTypes.string,
  cartTitle: PropTypes.string,
  cartDescription: PropTypes.string,
  cartItem: PropTypes.array,
  cartType: PropTypes.string,
  downloadProduct: PropTypes.string,
  inApp: PropTypes.bool
};

FreeProductcart.propTypes = {
  downloadProduct: PropTypes.string,
  cartTitle: PropTypes.string,
  cartDescription: PropTypes.string,
  inApp: PropTypes.bool
};

LicenseProductcartDropdown.propTypes = {
  cartItem: PropTypes.array,
  cartTitle: PropTypes.string
};


LicenseProductcart.propTypes = {
  cartTitle: PropTypes.string,
  cartDescription: PropTypes.string,
  cartItem: PropTypes.array
};

CreditProductCart.propTypes = {
  cartItem: PropTypes.array,
  cartTitle: PropTypes.string,
  cartDescription: PropTypes.string
};

export default Cart;