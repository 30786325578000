export const forumData = async (path) => {
    const url = `${process.env.REACT_APP_SUPPORT_FORUM_URL}${path}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
    }
};