import React from 'react';
import PropTypes from 'prop-types';
import '../../static/css/productPage/comparison.css';
import '../../static/css/productPage/characteristics.css';

const Comparison = ({ comarisonTable }) => {
    if (comarisonTable.length > 0 && Object.values(comarisonTable[0])[1] === true) {
        return (
            <div className='comparison' dangerouslySetInnerHTML={{ __html: Object.values(comarisonTable[0])[0] }}></div>
        );
    }
};

Comparison.propTypes = {
    comarisonTable: PropTypes.array
};

export default Comparison;