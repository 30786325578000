import React, { useEffect, useState } from 'react'
import ImageSlider from '../components/homePage/ImageSlider'
import '.././static/css/homePage/homePage.css'
import SoftwareUpdates from '../components/homePage/SoftwareUpdates'
import Categories from '../components/homePage/Categories'
import DisplayProducts from '../components/homePage/DisplayProducts'
import homeData from '.././data/homePage.json'
import FreeTrials from '../components/homePage/FreeTrials'
import ActiveDiscussions from '../components/homePage/ActiveDiscussions'
import HomeFooter from '../components/homePage/HomeFooter'
import { commonAPI } from '../api/commonAPI'

let cache = {};

export const HomePage = () => {
  document.title = 'Deskshare - Innovative and affordable software to help you excel'

  const [HomeData, setHomeData] = useState(null);

  useEffect(() => {
    if (cache['homepage']) {
      setHomeData(cache['homepage']);
    } else {
      commonAPI('homepage').then((result) => {
        cache['homepage'] = result;
        setHomeData(result);
      });
    }
  }, []);

  if (HomeData) {
    return (
      <>
        <ImageSlider images={HomeData.slider_images} />
        <Categories productId={HomeData.product_list} />
        <div className='home-page'>
          <DisplayProducts product={HomeData.product_list} />
          <SoftwareUpdates update={HomeData.product_updates} />
          <FreeTrials />
          <ActiveDiscussions discussion={homeData.discussion_list} />
        </div>
        <HomeFooter footer={homeData.footer} />
      </>
    )
  }
}
