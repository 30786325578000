import React, { useState } from 'react'
import '../../static/css/homePage/displayProducts.css'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DisplayProducts = ({ product }) => {
    const [playingVideo, setPlayingVideo] = useState(null);
    const [modal, setModal] = useState(false);

    const openVideo = (videoLink) => {
        if (videoLink) {
            setPlayingVideo(videoLink);
            setModal(true);
        }
    };

    let prevLength = 0;

    return (
        Object.keys(product).map((type) => {
            let currentLength = product[type].length;
            let className = '';

            if ((currentLength === 1 || currentLength === 2) && prevLength === 0) {
                className = 'side-by-side';
            } else if ((currentLength === 1 && prevLength === 2)) {
                className = 'side-by-side';
            } else if ((currentLength === 3 && prevLength === 3) || (currentLength === 2 && prevLength === 3) || (currentLength === 3 && prevLength === 2)) {
                className = 'one-below-one';
            }

            prevLength = currentLength;
            return (
                <div className={className === 'side-by-side' ? className : 'one-by-one'}>
                    <div className={`product-cart-container ${className === 'one-below-one' ? className : ''}`} key={uuid()}>
                        <h3 id={type}>{type}</h3>
                        <div className="products">
                            {
                                product[type].map((card) => {
                                    if (card.product_title) {
                                        return (
                                            <div className="product-card" key={uuid()}>
                                                <div className="button-block">
                                                    <Link className='learn-more' type="button" to={card.product_slug}>Learn More</Link>
                                                    <Link className='download' type="button" to={card.download_link}>Download</Link>
                                                </div>
                                                <Link to={card.product_slug}>
                                                    <div className='card-title'>
                                                        <LazyLoadImage className='card-icon' src={card.product_icon} alt={card.alt_text} />
                                                        <h4>{card.product_name}</h4>
                                                    </div>
                                                    <p>{card.product_title}</p>
                                                </Link>
                                                <button onClick={() => openVideo(card.video)}>
                                                    {
                                                        !card.video ?
                                                            <Link to={card.product_slug}>
                                                                <div>
                                                                    <LazyLoadImage src={card.homepage_video_image} alt={card.product_name} />
                                                                </div>
                                                            </Link> : <LazyLoadImage src={card.homepage_video_image} alt={card.product_name} />
                                                    }
                                                    {card.video ?
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58" fill="none" className='play-icon'>
                                                            <circle cx="29" cy="29" r="29" fill="red" fillOpacity="0.6" />
                                                            <path d="M40.3984 25.536C43.0651 27.0756 43.0651 30.9246 40.3984 32.4642L26.2984 40.6048C23.6318 42.1444 20.2984 40.2199 20.2984 37.1407L20.2984 20.8595C20.2984 17.7803 23.6318 15.8558 26.2984 17.3954L40.3984 25.536Z" fill="white" fillOpacity="1" />
                                                        </svg> : null}
                                                </button>
                                                {card.video ?
                                                    (playingVideo === card.video) && (
                                                        modal ?
                                                            <div className="VideoPopupDiv" id="VideoPopupDiv" style={{ display: 'block' }}>
                                                                <div className="InnerVideoDiv">
                                                                    <video
                                                                        autoPlay={true}
                                                                        controls
                                                                        id="ProductVideo"
                                                                        width="100%"
                                                                    >
                                                                        <track kind="captions" />
                                                                        <source id="ProductVideoSource" src={playingVideo}></source>
                                                                        Sorry, your browser doesn't support embedded videos.
                                                                    </video>
                                                                    <button className="CloseVideoPopupButton" onClick={() => setModal(false)}>
                                                                        <FontAwesomeIcon icon={faCircleXmark} />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            : null
                                                    )
                                                    : null}
                                            </div>
                                        )
                                    }
                                    else {
                                        return (
                                            <Link to={card.product_slug}>
                                                <div className='card-image'>
                                                    <LazyLoadImage src={card.homepage_video_image} alt="" />
                                                </div>
                                            </Link>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            )
        })
    )
}


DisplayProducts.propTypes = {
    product: PropTypes.object,
};

export default DisplayProducts