import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom';
import '../../static/css/detailedArticle.css';
import { fetchData } from '../../api/productPageAPI';
import { AddLangToPath } from '../../helper/AddLanguagePath';

const UserManual = () => {
    let { productSlug } = useParams();
    let { guideSlug } = useParams();
    const [manualData, setManualData] = useState(null);

    const changeImagePath = (content) => {
        const regex = /src=".*?"/g;
        const matches = content.match(regex);
        if (matches) {
            matches.forEach((match) => {
                const newMatch = match.replace('src="', `src="${process.env.REACT_APP_BACKEND_URL}`);
                content = content.replace(match, newMatch);
            });
        }

        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = content;

        const anchorTags = tempDiv.getElementsByTagName('a');
        for (let i = 0; i < anchorTags.length; i++) {
            const anchor = anchorTags[i];
            const href = anchor.getAttribute('href');
            anchor.setAttribute('href', AddLangToPath(`${href}`));
        }

        return tempDiv.innerHTML;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData(`${productSlug}/user_manual`).then((result) => setManualData(result));
    }, [productSlug]);

    if (manualData) {
        return (
            <div className='detailed-article'>
                <div className="more-article">
                    <h5 className='article-names'>User Guide</h5>
                    {
                        manualData.map((manual) => {
                            return (
                                <Link key={manual.id} to={AddLangToPath(`/help/${productSlug}/${manual.user_manual_slug}`)}>
                                    <p style={manual.user_manual_slug === guideSlug ? { backgroundColor: '#91bbfe' } : { color: 'black' }}>{manual.user_manual_title}</p>
                                </Link>
                            )
                        })
                    }
                </div>
                <div className="detailed-article-content">
                    <h1>
                        <Link to={AddLangToPath(`/${manualData[0].product_slug}`)}>
                            {manualData[0].product_name}
                        </Link>
                    </h1>
                    <hr />
                    {
                        manualData &&
                        (() => {
                            const manual = manualData.find(manual => manual.user_manual_slug === guideSlug);
                            if (manual) {
                                document.title = `${manual.product_name}: ${manual.user_manual_title}`
                                return (
                                    <>
                                        <h3>{manual.user_manual_title}</h3>
                                        <div dangerouslySetInnerHTML={{ __html: changeImagePath(manual.user_manual_content) }}></div>
                                    </>
                                );
                            } else {
                                return <h3>Manual not found</h3>
                            }
                        })()
                    }
                </div>
            </div>
        )
    }
}

export default UserManual 