import React from 'react';
import { Link as Scroll } from 'react-scroll';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';
import PropTypes from 'prop-types';
import '../../static/css/productPage/productFeatures.css';
import { useTranslation } from "react-i18next";

const ProductFeatures = ({ features, version, os, server, newVersion }) => {
    const { t } = useTranslation();

    const osLookup = {
        "Windows NT 10.0": "10",
        "Windows NT 6.3": "8.1",
        "Windows NT 6.2": "8",
        "Windows NT 6.1": "7",
        "Windows NT 6.0": "Vista",
        "Windows NT 5.1": "XP",
        "Windows NT 5.0": "2000",
        "Mac": "Mac/iOS",
        "X11": "UNIX",
        "Linux": "Linux"
    };

    let osVersion = "Unknown OS";
    for (let os in osLookup) {
        if (navigator.userAgent.indexOf(os) !== -1) {
            osVersion = osLookup[os];
            break;
        }
    }

    return (
        <div className="imp-feature">
            <div className='version'>
                <div className="title-text">{t('product_page.features.version')}:</div>
                <div className="content-text">{version}</div>
                <Link to={`whatsnew/${newVersion}`}>{t('product_page.features.new')}?</Link>
            </div>
            <div className="version">
                <span className="title-text">{t('product_page.features.platform')}:</span>
                <div className="content-text">
                    <span>
                        {os?.split(' ')?.map((word) =>
                            word === osVersion
                                ? <strong key={uuid()}>{` ${word}`}</strong>
                                : <span key={uuid()}>{` ${word}`}</span>
                        )}
                    </span>
                    <span>Windows {server}</span>
                </div>
            </div>
            <div className="feature-list">
                <span className="list-text">{t('product_page.features.title')}:</span>
                <ul>
                    {
                        features?.map((purchaseFeature) => {
                            return (
                                <li className='feature-text' key={uuid()}>{purchaseFeature?.feature}</li>
                            )
                        })
                    }
                </ul>
            </div>
            <Scroll
                to={"features"}
                spy={true}
                smooth={true}
                offset={-5}
                duration={1000}
                activeClass='active'
                className="feature-link">
                {t('product_page.features.all_features')} {">>"}
            </Scroll>
        </div>
    );
};

ProductFeatures.propTypes = {
    features: PropTypes.array,
    version: PropTypes.string,
    os: PropTypes.string,
    server: PropTypes.string,
    newVersion: PropTypes.string
};

export default ProductFeatures;