import React from 'react'
import '../../static/css/homePage/freeTrials.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { AddLangToPath } from '../../helper/AddLanguagePath';

const FreeTrials = () => {
    const { t } = useTranslation();
    return (
        <div className="try-for-free">
            <div className="try-for-free-content">
                <p>{t('homepage.free_trials.title')}</p>
                <Link to={AddLangToPath('/download')}>
                    <button className="trial-button">{t('homepage.free_trials.download_now')}</button>
                </Link>
            </div>
            <img src="/images/Try_for_free_background_banner.png" alt="Deskshare Logo" />
        </div>
    )
}

export default FreeTrials 