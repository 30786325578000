import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MdLanguage } from "react-icons/md";
import { faAngleDown, faCaretDown, faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import '../../static/css/common/header.css';
import IPDetails from '../../helper/IPDetails';
import { Helmet } from 'react-helmet';
import { commonAPI } from '../../api/commonAPI';
import { useTranslation } from 'react-i18next';
import { AddLangToPath } from '../../helper/AddLanguagePath';
import i18n from "i18next";

library.add(faAngleDown, faCaretDown, faBars, faXmark);

const AngleDownIcon = () => (
  <svg className="svg-inline--fa fa-angle-down down-arrow" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <desc>Down arrow</desc>
    <path fill="currentColor" d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
  </svg>
);

const languages = [
  { id: 'en', name: 'English' },
  { id: 'de', name: 'Deutsch' },
  { id: 'fr', name: 'Francaise' },
  { id: 'es', name: 'Spanish' },
  { id: 'pt', name: 'Portugués' },
  { id: 'it', name: 'Italiano' },
];

const Header = () => {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const [openProduct, setOpenProduct] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('lang') || 'en');
  const [productData, setProductData] = useState(null);

  useEffect(() => {
    if (!localStorage.getItem('lang')) {
      IPDetails().then((data) => {
        const ipLang = data.country_code.toLowerCase();
        const langExists = languages.some(lang => lang.id === ipLang);
        if (langExists) {
          setSelectedLanguage(ipLang);
          localStorage.setItem('lang', ipLang);
          i18n.changeLanguage(ipLang);
          if (ipLang !== 'en') {
            window.location.pathname = `/${ipLang}`;
          }
        } else {
          setSelectedLanguage('en');
          localStorage.setItem('lang', 'en');
          i18n.changeLanguage('en');          
        }
      });
    } else {
      const userLang = window.location.pathname.split('/')[1];
      const langExists = languages.some(lang => lang.id === userLang);
      if (langExists) {
        setSelectedLanguage(userLang);
        localStorage.setItem('lang', userLang);
        i18n.changeLanguage(userLang);
      } else {
        setSelectedLanguage('en');
        localStorage.setItem('lang', 'en');
        i18n.changeLanguage('en');
      }
    }
    commonAPI('product-header').then((result) => setProductData(result));
  }, [selectedLanguage]);

  const handleLanguage = (e) => {
    if (!e?.target?.value) return;
    const newLang = e.target.value;
    const langExists = languages.some(lang => lang.id === newLang);
    if (langExists) {
      setSelectedLanguage(newLang);
      localStorage.setItem('lang', newLang);
      let mypath = window.location.pathname.split('/');
      if (languages.some(lang => lang.id === mypath[1])) {
        mypath = mypath.slice(2);
      }
      mypath = mypath.filter(path => path !== '').join('/');
      if (newLang === 'en') {
        window.location.pathname = `/${mypath}`;
      } else {
        window.location.pathname = `/${newLang}/${mypath}`;
      }
    } else {
      setSelectedLanguage('en');
      localStorage.setItem('lang', 'en');
    }
  };

  const openMenuBar = useCallback(() => {
    setOpenMenu(prevOpenMenu => !prevOpenMenu);
  }, []);

  const handleKeyDown = useCallback((event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      openMenuBar();
    }
  }, [openMenuBar]);

  const openProductBar = useCallback(() => {
    setOpenProduct(prevOpenProduct => !prevOpenProduct);
    setTimeout(() => setOpenProduct(true), 100);
  }, []);

  return (
    <>
      <Helmet>
        <html lang={selectedLanguage}></html>
        <meta name="description" content="Deskshare offers innovative software for screen recording, video surveillance and FTP transfer for Windows users." />
        <meta name="keywords" content='' />
        <meta property="og:title" content='' />
        <meta property="og:description" content='' />
        <meta property="og:image" content='' />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="navigation">
        <div className='navbar' style={openMenu ? { position: 'fixed' } : { position: 'relative' }}>
          {
            window.innerWidth < 880 ?
              <button className="menu-bar" onClick={openMenuBar} onKeyDown={handleKeyDown}>
                {
                  !openMenu
                    ? <FontAwesomeIcon icon={faBars} className='menu-button' />
                    : <FontAwesomeIcon icon={faXmark} className='menu-button' />
                }
              </button> :
              ""
          }
          <div className="content-left">
            <Link to={AddLangToPath('')} className="deskshare-logo">
              <LazyLoadImage
                className="logo"
                alt="deskshare logo"
                to="/"
                src="/images/deskshare_logo.png"
              />
            </Link>
          </div>
          <div className={!openMenu ? "content" : "other-view-content"}>
            <div className="content-center">
              <div className="items product-container">
                <span>{t('header.product')}</span>
                <AngleDownIcon className="down-arrow" />
                {
                  openProduct ?
                    <div>
                      <div className='product-dropdown' style={(selectedLanguage === 'fr' || selectedLanguage === 'es' || selectedLanguage === 'it') ? { width: '90vw', left: '-11.028vw' } : null}>
                        {productData ?
                          Object.keys(productData)?.map((category) => {
                            return (
                              <div className="product-block">
                                <div className='product-content'>
                                  <p className='product-type'>{category}</p>
                                  <div className='product-list'>
                                    {productData[category].map((productId) => {
                                      return (
                                        <Link className='product-detail' onClick={() => { window.innerWidth > 430 ? openProductBar() : setOpenMenu(!openMenu) }} to={AddLangToPath(`/${productId.product_slug}`)}>
                                          <h5 className='product-name'>{productId.product_name}</h5>
                                          <p className='product-description'>{productId.product_title}</p>
                                        </Link>
                                      )
                                    })}
                                  </div>
                                </div>
                              </div>
                            )
                          }) : null
                        }
                      </div>
                    </div> : null
                }
              </div>
              <Link to={AddLangToPath('/download')} className="items" id="download" ><span>{t('header.download')}</span></Link>
              <div className="items product-container header-item">
                <span>{t('header.support.title')}</span>
                <AngleDownIcon className="down-arrow" />
                {openProduct ?
                  <div className='items-dropdown'>
                    <div className='support-list'>
                      <a className='product-detail' onClick={() => { openProductBar() }} href={`${process.env.REACT_APP_SUPPORT_FORUM_URL}/forum`}>
                        <h5 className='product-name'>{t('header.support.forum.title')}</h5>
                        <p className='product-description'>{t('header.support.forum.description')}</p>
                      </a>
                      <Link className='product-detail' onClick={() => { window.innerWidth > 430 ? openProductBar() : setOpenMenu(!openMenu) }} to={AddLangToPath("/product-support/")}>
                        <h5 className='product-name'>{t('header.support.technical_support.title')}</h5>
                        <p className='product-description' >{t('header.support.technical_support.description')}</p>
                      </Link>
                      <Link className='product-detail' onClick={() => { window.innerWidth > 430 ? openProductBar() : setOpenMenu(!openMenu) }} to={AddLangToPath("/lost-license-key/")}>
                        <h5 className='product-name'>{t('header.support.lost_license.title')}</h5>
                        <p className='product-description' >{t('header.support.lost_license.description')}</p>
                      </Link>
                      <Link className='product-detail' onClick={() => { window.innerWidth > 430 ? openProductBar() : setOpenMenu(!openMenu) }} to={AddLangToPath("/priority-support/")}>
                        <h5 className='product-name'>{t('header.support.purchase_support.title')}</h5>
                        <p className='product-description' >{t('header.support.purchase_support.description')}</p>
                      </Link>
                    </div>
                  </div>
                  : null
                }
              </div>
              <div className="items product-container header-item">
                <span>{t('header.resources.title')}</span>
                <AngleDownIcon className="down-arrow" />
                {openProduct ?
                  <div className='items-dropdown'>
                    <div className='support-list'>
                      <Link className='product-detail' onClick={() => { window.innerWidth > 430 ? openProductBar() : setOpenMenu(!openMenu) }} to={AddLangToPath("/articles/security-monitor-pro/")}>
                        <h5 className='product-name'>{t('header.resources.articles.title')}</h5>
                        <p className='product-description'>{t('header.resources.articles.description')}</p>
                      </Link>
                      <Link className='product-detail' onClick={() => { window.innerWidth > 430 ? openProductBar() : setOpenMenu(!openMenu) }} to={AddLangToPath("/tutorials-manuals/")}>
                        <h5 className='product-name'>{t('header.resources.video_tutorials.title')}</h5>
                        <p className='product-description' >{t('header.resources.video_tutorials.description')}</p>
                      </Link>
                    </div>
                  </div>
                  : null
                }
              </div>
              <div className="items product-container header-item">
                <span>{t('header.company.title')}</span>
                <AngleDownIcon className="down-arrow" />
                {openProduct ?
                  <div className='items-dropdown'>
                    <div className='support-list'>
                      <Link className='product-detail' onClick={() => { window.innerWidth > 430 ? openProductBar() : setOpenMenu(!openMenu) }} to={AddLangToPath("/about-deskshare/")}>
                        <h5 className='product-name'>{t('header.company.about')}</h5>
                      </Link>
                      <Link className='product-detail' onClick={() => { window.innerWidth > 430 ? openProductBar() : setOpenMenu(!openMenu) }} to={AddLangToPath("/contact-us/")}>
                        <h5 className='product-name'>{t('header.company.contact')}</h5>
                      </Link>
                    </div>
                  </div>
                  : null
                }
              </div>
            </div>
            <div className="auth">
              <Link className="signin" to={'https://shop.deskshare.com/login/'}>{t('header.sign_in')}</Link>
            </div>
          </div>
          <div className="select-language">
            <MdLanguage className='language-icon' />
            <select name="sel" id="sel" value={selectedLanguage} onChange={handleLanguage} >
              {languages.map(lang => <option key={lang.id} value={lang.id}>{lang.name}</option>)}
            </select>
            <FontAwesomeIcon icon={faCaretDown} className='language-dropdown' />
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;