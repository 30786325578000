import React from 'react'
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Slide } from 'react-slideshow-image';
import uuid from 'react-uuid';
import { Link } from 'react-router-dom';
import '../../static/css/homePage/imageSlider.css';
import { AddLangToPath } from '../../helper/AddLanguagePath';

const ImageSlider = ({ images }) => {
    const downloadImage = {
        "slider_image": "/images/heroslidersectionimg.webp",
        "product_title": "download",
        "product_slug": AddLangToPath("/download")
    };

    if (!images.some(image => image.product_slug === downloadImage.product_slug)) {
        images.unshift(downloadImage);
    }

    return (
        <div className='image-slider'>
            {window.innerWidth > 430 ?
                <Slide scale={1.4} slidesToScroll={1} slidesToShow={1} indicators={true} arrows={false} duration={3000} transitionDuration={2000}>
                    {
                        images.map((imageContent) => (
                            <div key={uuid()}>
                                <Link to={imageContent.product_slug}>
                                    <LazyLoadImage style={{ objectFit: 'contain', width: '100%' }} src={imageContent.slider_image} alt={imageContent.product_title} />
                                </Link>
                            </div>
                        ))
                    }
                </Slide>
                :
                <div className='image-box'>
                        <LazyLoadImage src={downloadImage.slider_image} alt={downloadImage.product_title} />
                </div>
            }
        </div>
    )
}

ImageSlider.propTypes = {
    images: PropTypes.array.isRequired
};

export default ImageSlider