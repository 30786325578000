import React, { useEffect, useState } from 'react'
import '../../static/css/company/aboutDeskshare.css'
import { Link } from 'react-router-dom'
import { commonAPI } from '../../api/commonAPI'
import { useTranslation } from 'react-i18next'
import { AddLangToPath } from '../../helper/AddLanguagePath'

const AboutDeskshare = () => {
  const { t } = useTranslation()
  document.title = t('company.about_us.title')
  const [aboutUsData, setAboutUsData] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    commonAPI('about-us').then((result) => setAboutUsData(result));
  }, []);

  if (aboutUsData) {
    return (
      <>
        <div className='aboutus-header'>
          <h1>{t('company.about_us.title')}</h1>
          <p>{t('company.about_us.description')}<q>{t('company.about_us.quote')}</q>.</p>
        </div>
        <div className='aboutus-container'>
          <button>{t('company.about_us.subscription')}</button>
          <p className='what-we-do'>{t('company.about_us.question')}</p>
          <div className='deskshare-info'>
            <div className="deskshare-icon">
              <img src="/images/deskshare_logo.png" alt="deskshare logo" />
            </div>
            <div className='deskshare-about'>
              <p>Deskshare{t('company.about_us.answer')}</p>
            </div>
          </div>
          <p className='solution'>{t('company.about_us.solution')}</p>
          <div className='product-cards' >
            {Object.entries(aboutUsData).map(([categoryName, products]) => (
              <div key={categoryName} className='product-container'>
                {products.map(product => (
                  product.product_name && (
                    <Link key={product.product_name} to={AddLangToPath(`/${product.product_slug}`)}>
                      <div className='product-box'>
                        <div className="product-icon-name">
                          <img src={`${process.env.REACT_APP_BACKEND_URL}/media/${product.product_icon}`} alt={product.product_name} />
                          <h4>{product.product_name}</h4>
                        </div>
                        <p>{product.product_title}</p>
                      </div>
                    </Link>
                  )
                ))}
              </div>
            ))}
          </div>
          <p className='press'>{t('company.about_us.press')}</p>
          <div className="media-container">
            <Link to={AddLangToPath('/media-coverage/')}>
              <p className='media-title' >{t('company.about_us.media')}</p>
              <p className='media-description'>{t('company.about_us.media_description')}</p>
            </Link>
          </div>
        </div>
      </>
    )
  }
}

export default AboutDeskshare