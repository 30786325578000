import React from 'react';
import PropTypes from 'prop-types';
import '../../static/css/productPage/productInfo.css';

const ProductInfo = ({ detail }) => {
    return (
        <div className='product-info'>
            <div className='lines' dangerouslySetInnerHTML={{ __html: detail }}></div>
        </div>
    );
};

ProductInfo.propTypes = {
    detail: PropTypes.string
};

export default ProductInfo;