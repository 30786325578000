import React from 'react'
import PropTypes from 'prop-types';
import uuid from 'react-uuid'
import { Link } from 'react-router-dom'
import '../../static/css/homePage/softwareUpdates.css'
import { useTranslation } from 'react-i18next';

const SoftwareUpdates = ({ update }) => {
    const { t } = useTranslation();
    return (
        <div className='updates'>
            <p className='update-title'>{t('homepage.software_updates')}</p>
            <div className='product-updates' key={uuid()}>
                <table className='updates-list' >
                    <tbody>
                        {
                            update.map((update) => {
                                return (
                                    <tr key={uuid()}>
                                        <td className='update-bullet'>{">"}</td>
                                        <td className='updated-product-name'>
                                            <Link to={update.product_slug}><span>{update.product_name}</span></Link>
                                        </td>
                                        <td className='product-version'>
                                            <Link to={update.product_slug}>{update.current_version}</Link>
                                        </td>
                                        <td className='update-date'>{update.updated_date}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

SoftwareUpdates.propTypes = {
    update: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        version: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired
    }))
}

export default SoftwareUpdates