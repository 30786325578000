import React, { useEffect } from 'react'
import '../../static/css/supportPages/supportForums.css'

const SupportForums = () => {
  document.title = 'Forums'

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>SupportForums</div>
  )
}

export default SupportForums