import React, { useEffect } from 'react'
import '.././static/css/privacyPolicy.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const PrivacyPolicyApp = () => {
    const { t } = useTranslation()
    document.title = t('privacy_policy_app.title');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="privacy-policy-page">
            <div className="policy-header">
                <h1>{t('privacy_policy_app.title')}</h1>
            </div>
            <div className="privacy-policy">
                <div className="privacy-policy-about">
                    <b>{t('privacy_policy_app.title')}</b>
                    <p>{t('privacy_policy_app.description')}</p>
                </div>
                <div className="privacy-policy-content">
                    <ol>
                        <li><b>{t('privacy_policy_app.bullet1.title')}</b>
                            <p><strong>{t('privacy_policy_app.bullet1.point_title1')}: </strong>{t('privacy_policy_app.bullet1.point1')}</p>
                            <p><strong>{t('privacy_policy_app.bullet1.point_title2')}: </strong>{t('privacy_policy_app.bullet1.point2')}</p>
                        </li>
                        <li><b>{t('privacy_policy_app.bullet2.title')}</b>
                            <p><strong>{t('privacy_policy_app.bullet1.point_title1')}: </strong>{t('privacy_policy_app.bullet2.point1')}</p>
                        </li>
                        <li><b>{t('privacy_policy_app.bullet3.title')}</b>
                            <p>{t('privacy_policy_app.bullet3.point1')}</p>
                        </li>
                        <li><b>{t('privacy_policy_app.bullet4.title')}</b>
                            <p>{t('privacy_policy_app.bullet4.point1')}</p>
                        </li>
                        <li><b>{t('privacy_policy_app.bullet5.title')}</b>
                            <p>{t('privacy_policy_app.bullet5.point1')}</p>
                        </li>
                        <li><b>{t('privacy_policy_app.bullet6.title')}</b>
                            <p>{t('privacy_policy_app.bullet6.point1')}</p>
                        </li>
                        <li><b>{t('privacy_policy_app.bullet7.title')}</b>
                            <p>{t('privacy_policy_app.bullet7.point1')}<Link to={'/contact-us'}>{t('privacy_policy_app.bullet7.contact')}</Link>.</p>
                        </li>
                    </ol>
                </div>
                <p className='updteded-date'>{t('privacy_policy_app.date')}: 5 May 2023</p>
            </div>
        </div>
    )
}

export default PrivacyPolicyApp