import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import '../static/css/articleBlock.css';
import uuid from 'react-uuid';
import { useTranslation } from 'react-i18next';
import { AddLangToPath } from '../helper/AddLanguagePath';

const ArticleBlock = ({ data }) => {
    const { t } = useTranslation();
    let { articleSlug } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const changeImagePath = (content) => {
        const regex = /src=".*?"/g;
        const matches = content.match(regex);
        if (matches) {
            matches.forEach((match) => {
                const newMatch = match.replace('src="', `src="${process.env.REACT_APP_BACKEND_URL}`);
                content = content.replace(match, newMatch);
            });
        }

        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = content;

        const anchorTags = tempDiv.getElementsByTagName('a');
        for (let i = 0; i < anchorTags.length; i++) {
            const anchor = anchorTags[i];
            const href = anchor.getAttribute('href');
            anchor.setAttribute('href', AddLangToPath(`${href}`));
        }

        return tempDiv.innerHTML;
    }

    return (
        <div className="article">
            <h3 className='articles-product-name'>
                <Link to={AddLangToPath(`/${data[articleSlug].product_slug}`)}>
                    {data[articleSlug].product_name}
                </Link>
            </h3>
            {
                Object.keys(data[articleSlug].article).map((article) => {
                    return (
                        <Link to={`/${data[articleSlug].product_name_slug}/${article}`} key={uuid()} className="article-content">
                            <div className="article-title">
                                <p>{data[articleSlug].article[article].article_title}</p>
                            </div>
                            <div className='article-description'>
                                <span dangerouslySetInnerHTML={{ __html: changeImagePath(data[articleSlug].article[article].article_content.split(' ').slice(0, 40).join(' ')) }}></span>
                                {" "}<Link to={`${data[articleSlug].product_name_slug}/${article}`}>{t('resources.articles.read_more')}...</Link>
                            </div>
                        </Link>
                    );
                }
                )
            }
        </div>
    )
}

ArticleBlock.propTypes = {
    data: PropTypes.shape({
        headingLink: PropTypes.string,
        sidebarLink: PropTypes.string,
        articleHeading: PropTypes.string,
        articles: PropTypes.arrayOf(PropTypes.shape({
            subHeading: PropTypes.string,
            article: PropTypes.string,
        })),
    }),
};

export default ArticleBlock