import React from 'react';
import PropTypes from 'prop-types';
import Video from './Video';
import ProductFeatures from './ProductFeatures';
import Cart from './Cart';
import '../../static/css/productPage/product.css';


const Product = ({
  productVideo,
  productVideoImage,
  productImages,
  os,
  server,
  currentVersion,
  productFeatures,
  newVersion,
  downloadLink,
  type,
  inApp,
  cartItem,
  cartType,
  cartTitle,
  cartDescription,
  downloadProduct
}) => {
  return (
    <div className='product'>
      <Video video={productVideo} videoImage={productVideoImage} images={productImages} />
      <ProductFeatures version={currentVersion} os={os} server={server} features={productFeatures} newVersion={newVersion} />
      <Cart download={downloadLink} productType={type} inApp={inApp} cartItem={cartItem} cartType={cartType} cartTitle={cartTitle} cartDescription={cartDescription} downloadProduct={downloadProduct} />
    </div>
  );
};

Product.propTypes = {
  productVideo: PropTypes.arrayOf(PropTypes.string),
  productVideoImage: PropTypes.string,
  productImages: PropTypes.arrayOf(PropTypes.string),
  os: PropTypes.arrayOf(PropTypes.string),
  server: PropTypes.arrayOf(PropTypes.string),
  currentVersion: PropTypes.string,
  productFeatures: PropTypes.arrayOf(PropTypes.string),
  newVersion: PropTypes.string,
  downloadLink: PropTypes.string,
  type: PropTypes.string,
  inApp: PropTypes.string,
  cartItem: PropTypes.string,
  cartType: PropTypes.string,
  cartTitle: PropTypes.string,
  cartDescription: PropTypes.string,
  downloadProduct: PropTypes.string
};

export default Product