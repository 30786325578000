import React, { useEffect, useState } from 'react';
import '../../static/css/detailedArticle.css';
import { useParams, Link } from 'react-router-dom';
import { commonAPI } from '../../api/commonAPI';
import { useTranslation } from 'react-i18next';
import { AddLangToPath } from '../../helper/AddLanguagePath';

const DetailedArticle = () => {
    const { t } = useTranslation();
    let { detailedArticleSlug } = useParams();
    let { articleSlug } = useParams();
    const [articleData, setArticleData] = useState(null);

    const changeImagePath = (content) => {
        const regex = /src=".*?"/g;
        const matches = content.match(regex);
        if (matches) {
            matches.forEach((match) => {
                const newMatch = match.replace('src="', `src="${process.env.REACT_APP_BACKEND_URL}`);
                content = content.replace(match, newMatch);
            });
        }

        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = content;

        const anchorTags = tempDiv.getElementsByTagName('a');
        for (let i = 0; i < anchorTags.length; i++) {
            const anchor = anchorTags[i];
            const href = anchor.getAttribute('href');
            anchor.setAttribute('href', AddLangToPath(`${href}`));
        }

        return tempDiv.innerHTML;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        commonAPI(`allarticles/${articleSlug}`).then((result) => setArticleData(result[articleSlug]));
    }, [articleSlug]);

    if (articleData) {
        document.title = `${t('resources.articles.title')} - ${articleData.article[detailedArticleSlug].article_title}`;
        return (
            <div className='detailed-article'>
                <div className="more-article">
                    <h5 className='article-names'>{t('resources.articles.detail_side_bar')}</h5>
                    {
                        Object.keys(articleData.article).map((articleName) => {
                            return (
                                <a key={articleName.id} href={AddLangToPath(`/${articleSlug}/${articleName}`)}>
                                    <p style={articleName === detailedArticleSlug ? { backgroundColor: '#91bbfe' } : { color: 'black' }}>{articleData.article[articleName].article_title}</p>
                                </a>
                            )
                        })
                    }
                </div>
                <div className="detailed-article-content">
                    <h1>
                        <Link to={AddLangToPath(`/${articleData.product_slug}`)}>{articleData.product_name}</Link>
                    </h1>
                    <hr />
                    <h3>{articleData.article[detailedArticleSlug].article_title}</h3>
                    <div dangerouslySetInnerHTML={{ __html: changeImagePath(articleData.article[detailedArticleSlug].article_content) }}></div>
                </div>
            </div>
        )
    }
}

export default DetailedArticle;