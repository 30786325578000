import React from 'react';
import PropTypes from 'prop-types';
import '../../static/css/productPage/productTitle.css';

const ProductTitle = ({ title, subTitle }) => {
  return (
    <div className='product-title'>
      <h1 className='title'>{title}</h1>
      <h6 className='sub-title'>{subTitle}</h6>
    </div>
  );
};

export default ProductTitle;

ProductTitle.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};